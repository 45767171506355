import React from 'react';
import ReactECharts from 'echarts-for-react';

const TicketsPerClient: React.FC = () => {
  const options = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '-5px',
    left: 'center'
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  series: [
    {
      name: 'Customer',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1384, name: 'FLEXSUR' },
        { value: 418, name: 'JABIL' },
        { value: 336, name: 'JABIL CUU' },
        { value: 318, name: 'HARMAN' },
        { value: 253, name: 'CONTI AGS' },
        { value: 250, name: 'UEI' },
        { value: 218, name: 'SKYWORKS' },
        { value: 177, name: 'USI' },
        { value: 165, name: 'METHODE' },
        { value: 153, name: 'QUANTA' },
      ]
    }
  ]
};

  return <ReactECharts option={options} />;
};

export default TicketsPerClient;