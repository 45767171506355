import UserOrgDocUpload from "./UserOrgDocUpload";
import UserOrgDocMain from "./UserOrgDocMain";
import LoadingBar from "../ui/LoadingBar";
import { useState, useEffect } from "react";
import useFetchClickFormFiles from "../../hooks/useFetchClickFormFiles";
import useFetchClickForm from "../../hooks/useFetchClickForm";

import useFetch from "../../hooks/useFetch";
import { dateFormatDB, dateFormatExpired } from "../../utils/constants";
import { format } from "date-fns";

export default function UserOrgDoc(props) {
    const { userId, fileField, handleRequestFilesExist, setTabContent } = props;
    const [showCompleteForm, setShowCompleteForm] = useState(false);
    const [bodyFileFetch, setBodyFileFetch] = useState({
        "id": userId,
        "file_type_id": fileField.file_type_id,
        "datetime": new Date(),
        "expiration_date": format(new Date(), dateFormatDB),
        "files": [],
        "is_encrypt": fileField.encrypt
    });
    const handleFilesChange = (event) => {
        //
        setBodyFileFetch({
            ...bodyFileFetch,
            "files": Array.from(event.target.files)
        })

    }

    const dateHandle = (dt) => {
        setBodyFileFetch({
            ...bodyFileFetch,
            "datetime": dt,
            "expiration_date": format(dt, dateFormatDB),
        })
    }
    const clearFilesHandle = () => {
        setBodyFileFetch({
            ...bodyFileFetch,
            "files": [],
        })
    }
    const { data: filesData, loading: filesLoading, error: filesError, handleRequest: handleGetFiles } = useFetch(
        `${process.env.REACT_APP_API_URL}get_user_file`,
        { "user_file_id": fileField.user_file_id },
        false
    )
    useEffect(() => {
        if (fileField.file_exists) {
            handleGetFiles();
        }
    }, [])
    
    const changeTab = (val)=>{
        setTabContent("INFO");
    }
    
    const { loading: loadingSubmit, error: errorSubmit, handleRequest: handleRequestSubmit } = useFetchClickFormFiles(
        `${process.env.REACT_APP_API_URL}${fileField.file_exists ? "update_user_files" : "add_user_files"}`,
        bodyFileFetch,handleRequestFilesExist, changeTab
    );
    const submitFileHandle = () => {
        handleRequestSubmit();
    }

    const { loading: loadingDelete, error: errorDelete, handleRequest: handleRequestDelete } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}delete_user_file`,
        {user_file_id:fileField.user_file_id},handleRequestFilesExist, changeTab
    );
    const submitFileDeleteHandle = () => {
        handleRequestDelete()
    }
    return (
        <div className="my-4 rounded-lg shadow-lg p-4 bg-gray-200">
            <div 
                onClick={()=>setShowCompleteForm(!showCompleteForm)} 
                className="flex ring-4 ring-gray-400 hover:ring-gray-600 rounded-sm pt-2 pl-2">
                <label for="large-input" class="block mb-2 text-lg font-medium text-black mr-2">{fileField.name}</label>
                {
                    fileField.encrypt && (<div className="badge badge-warning mt-1 mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="inline-block h-4 w-4 stroke-current">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"></path>
                        </svg>
                        
                    </div>)
                }
                {
                    fileField.file_exists && (<div className="badge badge-info mt-1 mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="inline-block h-4 w-4 stroke-current">
                            <path fill="currentColor" d="M16 18H8l2.5-6 2 4 1.5-2 2 4Zm-1-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM8 18h8l-2-4-1.5 2-2-4L8 18Zm7-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"></path>
                        </svg>
                    </div>)
                }
                {
                    (fileField.expired) && (<div className="badge badge-success mt-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="inline-block h-4 w-4 stroke-current">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                        </svg>
                        {fileField.expiration_date ? format(new Date(fileField.expiration_date), dateFormatExpired) : "NA"}
                    </div>)
                }
            </div>
            {
                showCompleteForm &&
                <div>
                    <LoadingBar loading={loadingSubmit || loadingDelete || filesLoading} style="mt-2" />
                    <div>
                        <p className="pb-2">{fileField.comment}</p>
                    </div>
                    {
                        bodyFileFetch.files.length ?
                            <UserOrgDocUpload
                                dateHandle={dateHandle}
                                files={bodyFileFetch.files}
                                expired={fileField.expired}
                                clearFilesHandle={clearFilesHandle}
                                handleRequestSubmit={submitFileHandle} /> :
                            <UserOrgDocMain
                                filesData={filesData}
                                loading={filesLoading}
                                fileField={fileField}
                                handleFilesChange={handleFilesChange}
                                submitFileDeleteHandle={submitFileDeleteHandle} />
                    }
                </div>

            }



        </div>
    );
}