import AddAndUpdateUserBody from "./AddAndUpdateUserBody";
import { dateFormatDB } from "../../utils/constants";
import { format } from "date-fns";

export default function UserOrgEdit(props) {
    const { userInfo, handleForm, dashboardRequest } = props;
    return (
        <form onSubmit={e => { e.preventDefault(); }}>
            <AddAndUpdateUserBody
                organization_id={userInfo.user_organization_id}
                dashboardRequest={dashboardRequest}
                handleForm={handleForm}
                body={{
                    birthday_obj: new Date(),
                    birthday: format(new Date(), dateFormatDB),
                    hired_obj: new Date(),
                    hired: format(new Date(), dateFormatDB),
                    complete_name: "",
                    user_name: "",
                    account_active: true,
                    location: "",
                    position: "",
                    email: "",
                    pwd: "",
                    superior_id: 0,
                    phone_number: "",
                    user_type:userInfo.roles.userType,
                    permissions:{},
                    personal_info: {}
                }}
            />
        </form>
    );
}