import { Link, Outlet } from "react-router-dom";
import TicketForm from "../form/ticketForm";
import ChatTicket from "./chatTicket";
import Interactions from "./interactions";
import { useState } from "react";

import { io } from "socket.io-client";


function Ticket(props) {
	var { ticketId, refresh } = props;
	var ticketData = "";



	return (
		<div className="flex bg-base-100">
			<div className="w-2/6 p-6 ">
				<TicketForm ticketId={ticketId} refresh={refresh} />
			</div>
			<div className="border-l-2 border-r-2 border-base-200 w-4/6 p-6">
				<ChatTicket ticketId={ticketId} refresh={refresh} ticketData={ticketData} />
			</div>
			<div className=" w-2/6 p-6 max-h-[60rem] overflow-y-scroll">
				<Interactions ticketId={ticketId} refresh={refresh} />
			</div>
		</div>
	);
}

export default Ticket;
