import AlertComplete from "../ui/AlertComplete";
import TableBasic from "../tables/TableBasic";
import useFetch from "../../hooks/useFetch";
import OrganizationButtons from "./OrganizationButtons";
import { useEffect } from "react";
import { getUserData } from "../../utils/utils";
import AlertMessage from "../ui/AlertMessage";

export default function OrganizationWall({setOrgId, orgId}) {
    const userLogin = getUserData();
    const userFileMissing = useFetch(`${process.env.REACT_APP_API_URL}user_files_missings`, {
        user_id: userLogin.userId,
        organization_id: orgId
    })
    const userFileExpired = useFetch(`${process.env.REACT_APP_API_URL}user_files_expired`, {
        user_id: userLogin.userId,
        organization_id: orgId
    })
    const userWallEvents = useFetch(`${process.env.REACT_APP_API_URL}user_wall_events`, {
        organization_id: orgId
    })
    const userMonthBirthday = useFetch(`${process.env.REACT_APP_API_URL}user_month_birthday`, {
        organization_id: orgId
    })
    const userCurrVacation = useFetch(`${process.env.REACT_APP_API_URL}user_current_vacation`, {
        organization_id: orgId
    })
    useEffect(() => {
        userFileMissing.handleRequest();
        userFileExpired.handleRequest();
        userWallEvents.handleRequest();
        userMonthBirthday.handleRequest();
        userCurrVacation.handleRequest();
    }, [orgId])
    return (
        <div>
            <OrganizationButtons
                dashboardRequest={userWallEvents.handleRequest}
                organization_id={userLogin.organizationId}
                setOrgId={setOrgId}
                orgId={orgId} />
            <AlertMessage  error={userFileMissing.error} style="mb-4"/>
            <AlertMessage  error={userFileExpired.error} style="mb-4"/>
            <AlertMessage  error={userWallEvents.error} style="mb-4"/>
            <AlertMessage  error={userMonthBirthday.error} style="mb-4"/>
            <AlertMessage  error={userCurrVacation.error} style="mb-4"/>
            <div className="w-full grid lg:grid-cols-3 gap-5 mb-8">
                {
                    userMonthBirthday.data.length ?
                    (<AlertComplete
                        title={<h3 className="pl-4 font-bold flex">
                            🎁🍾 Birthdays of the Month 🍰🎉
                            <p className="ml-2 bg-gray-600 text-white px-1 rounded-md">
                                #{userMonthBirthday.data.length}</p>
                        </h3>}
                        msg={<TableBasic
                            data={userMonthBirthday.data}
                            styleBody="bg-blue-100 px-2 rounded-md"
                            styleCols="bg-blue-300"
                            styleRows="border-t-4 border-blue-400" />}
                        type="info" />):null
                }
                {
                    userWallEvents.data.length ?
                    (<AlertComplete
                        title={<h3 className="pl-4 font-bold flex">
                            📆 Upcoming Events.
                            <p className="ml-2 bg-gray-600 text-white px-1 rounded-md">
                                #{userWallEvents.data.length}</p>
                        </h3>}
                        msg={<TableBasic
                            data={userWallEvents.data}
                            styleBody="bg-blue-100 px-2 rounded-md"
                            styleCols="bg-blue-300"
                            styleRows="border-t-4 border-blue-400" />}
                        type="basic"
                        buttonStyle="bg-info text-white rounded-md px-2 hover:ring-4" />):null
                }

                {
                    userCurrVacation.data.length ?
                    (<AlertComplete
                        title={<h3 className="pl-4 font-bold flex">
                            🏕️🏔️ Vacations ✈️🏖️
                            <p className="ml-2 bg-gray-600 text-white px-1 rounded-md">
                                #{userCurrVacation.data.length}</p>
                        </h3>}
                        msg={<TableBasic
                            data={userCurrVacation.data}
                            styleBody="bg-green-100 px-2 rounded-md"
                            styleCols="bg-green-300"
                            styleRows="border-t-4 border-green-400" />}
                        type="success" />):null
                }
                {
                    userFileExpired.data.length ?
                    (<AlertComplete
                        title={<h3 className="pl-4 font-bold flex">
                            🗃️📆 Expired Documents.
                            <p className="ml-2 bg-gray-600 text-white px-1 rounded-md">
                                #{userFileExpired.data.length}</p>
                        </h3>}
                        msg={<TableBasic
                            data={userFileExpired.data}
                            styleBody="bg-amber-100 px-2 rounded-md"
                            styleCols="bg-amber-300"
                            styleRows="border-t-4 border-amber-400" />}
                        type="warnign" />):null
                }
                {
                    userFileMissing.data.length ?
                    (<AlertComplete
                        title={<h3 className="pl-4 font-bold flex">
                            🗃️📭 Missing Documents.
                            <p className="ml-2 bg-gray-600 text-white px-1 rounded-md">
                                #{userFileMissing.data.length}</p>
                        </h3>}
                        msg={<TableBasic
                            data={userFileMissing.data}
                            styleBody="bg-red-100 px-2 rounded-md"
                            styleCols="bg-red-300"
                            styleRows="border-t-4 border-red-400" />}
                        type="error" />):null
                }

            </div>
        </div>

    );
}