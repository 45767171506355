import { useState } from "react";
import { handleImage } from "../../utils/utils";
import UserOrgForm from "../form/UserOrgForm";

export default function OrganizationCard(props) {
    const { userInfo, dashboardRequest } = props;
    const [showEdit, setShowEdit] = useState(false);
    const colorSelect = (color) => {
        const listColors = {
            "green": "rounded-lg card w-24 m-4 ring-4 ring-green-600 hover:ring-8",
            "violet": "rounded-lg card w-24 m-4 ring-4 ring-violet-600 hover:ring-8",
            "orange": "rounded-lg card w-24 m-4 ring-4 ring-orange-600 hover:ring-8",
            "fuchsia": "rounded-lg card w-24 m-4 ring-4 ring-fuchsia-600 hover:ring-8",
            "pink": "rounded-lg card w-24 m-4 ring-4 ring-pink-600 hover:ring-8",
            "slate": "rounded-lg card w-24 m-4 ring-4 ring-slate-600 hover:ring-8",
            "red": "rounded-lg card w-24 m-4 ring-4 ring-red-600 hover:ring-8"
        }
        return listColors[color]
    }
    const profile_image = userInfo.profile_image ? handleImage(userInfo.profile_image.data) : "/images/Profile_Default.png"

    return (
        <>
            <div
                className={colorSelect(userInfo["color"])}
                onClick={() => setShowEdit(true)}>
                {
                    userInfo.vacation &&
                    (<p className="text-center bg-info text-xs text-bold mx-1 my-1 p-1 rounded-lg" >
                        Vacation<br /> ✈️🏖️🏕️🏔️</p>)
                }
                {
                    userInfo.month_birthday &&
                    (<p className="text-center bg-success text-xs text-bold mx-1 my-1 p-1 rounded-lg" >
                        Bridthday<br /> 🍰🎉🎁🍾</p>)
                }
                <figure >
                    <img
                        src={profile_image}
                        alt="profile"
                        width="100%" />
                </figure>
                <h2 className="text-xs text-center bg-black text-white py-1 font-bold">
                    {userInfo["user_position"]}

                </h2>
                <div className="px-2 pb-2">

                    <h4 className="text-sm text-center pt-2 font-bold">
                        {userInfo["user_name"]}
                    </h4>
                </div>

            </div>
            <UserOrgForm
                show={showEdit}
                handleForm={setShowEdit}
                title="Account"
                userInfo={userInfo}
                dashboardRequest={dashboardRequest} />
        </>

    );
}