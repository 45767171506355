import UserList from "../../components/ui/userList";
import ScheduleUser from "../../components/ui/ScheduleUser";
import React, { useState, useEffect } from "react";
import SelectUsers from "../../components/form/selectUsers";

import axios from "axios";
import { format } from "date-fns";

import { toast } from "react-toastify";
import SelectClients from "../../components/form/selectClients";

function TicketInfo(props) {
	const { ticketId, refresh } = props;
	const [userAssigned, setUserAssigned] = useState({});
	const [clientSelected, setClientSelected] = useState();

	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "tickets/" + ticketId;
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [errorType, setErrorType] = useState("");

	console.log(errorType);

	const [test, setTest] = useState(null);

	useEffect(() => {
		console.log("ready");
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data[0]);
				console.log(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	if (ticketId && loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	return (
		<>
			{data !== null ? (
				<form>
					<h2 class="card-title mt-6">Ticket Info</h2>
					<div class="divider"></div>

					<div class="form-control">
						<label class="label cursor-pointer">
							<span class="label-text">Hardware</span>
							<input
								type="radio"
								name="errorType"
								class="radio checked:bg-red-500"
								value="hardware"
								onChange={() => setErrorType("hardware")}
								checked={data.errorType === "hardware"}
							/>
						</label>
					</div>
					<div class="form-control">
						<label class="label cursor-pointer">
							<span class="label-text">Software</span>
							<input
								type="radio"
								name="errorType"
								class="radio checked:bg-blue-500"
								value="software"
								onChange={() => setErrorType("software")}
								checked={data.errorType === "software"}
							/>
						</label>
					</div>

					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">Date</span>
						</label>
						<input
							type="date"
							placeholder="Type here"
							className="input input-bordered w-full"
							name="date"
							defaultValue={data.date.slice(0, 10)}
						/>
					</div>

					<div className="form-control w-full">
						<label className="label">
							<span className="label-text">Inquiry Type</span>
						</label>
						<select
							className="input input-bordered w-full"
							name="inquityType"
							default={data.inquiryType}
						>
							<option value="recovered" label="Host error (Nexim/Flexa/Trax/Accessory)" />
							<option
								value="recovered"
								label="Other error (Application/Services/Image processing)"
							/>
							<option
								value="recovered"
								label="Damage/malfunction of mechanical/electrical parts"
							/>
							<option value="recovered" label="Interference" />
							<option value="recovered" label="Poor mounting accuracy/missing parts" />
							<option value="recovered" label="Wrong parts places" />

							<option value="recovered" label="Module/machine failed to place any parts" />
							<option value="recovered" label="Panel transfer error" />
							<option value="recovered" label="Other trouble" />
							<option value="recovered" label="Question" />
							<option value="recovered" label="Request" />
							<option value="recovered" label="Knowledge posting request" />
						</select>
					</div>

					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">Equipment Status</span>
						</label>
						<select
							className="input input-bordered w-full"
							name="equipmentStatus"
							defaultValue={data.equipmentStatus}
						>
							<option value="no stop" label="no stop" />
							<option value="intermittent stop" label="intermittent stop" />
							<option value="breakdown mode" label="breakdown mode" />
							<option value="completely stopped" label="completely stopped" />
							<option value="recovered" label="recovered" />
						</select>
					</div>

					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">Frequency Of Ocurrance</span>
						</label>
						<select
							className="input input-bordered w-full"
							name="frecuencyOfOcurrence"
							defaultValue={data.frecuencyOfOcurrence}
						>
							<option value="every time" label="every time" />
							<option value="several times a day" label="several times a day" />
							<option value="several times a week" label="several times a week" />
							<option value="several times a month" label="several times a month" />
							<option value="once so far" label="once so far" />
							<option value="several times so far" label="several times so far" />
						</select>
					</div>

					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">Error Code</span>
						</label>
						<input
							type="text"
							placeholder="Type here"
							className="input input-bordered w-full"
							name="errorCode"
							defaultValue={data.errorCode}
						/>
					</div>

					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">On-site Work History</span>
						</label>
						<textarea
							class="textarea textarea-bordered h-24"
							placeholder="Type here"
							name="onSiteWorkHistory"
							defaultValue={data.onSiteWorkHistory}
						></textarea>
					</div>

					<div className={data.errorType !== "hardware" ? "hidden" : ""}>
						<div className="divider w-full mt-10">Hardware Info</div>
						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Nexim</span>
								<input
									type="radio"
									name="hardwareType"
									className="radio checked:bg-blue-500"
									checked={data.hsInfo.hardwareType === "nexim"}
								/>
							</label>
						</div>
						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Flexa</span>
								<input
									type="radio"
									name="hardwareType"
									className="radio checked:bg-blue-500"
									checked={data.hsInfo.hardwareType === "flexa"}
								/>
							</label>
						</div>
						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Trax</span>
								<input
									type="radio"
									name="hardwareType"
									className="radio checked:bg-blue-500"
									checked={data.hsInfo.hardwareType === "trax"}
								/>
							</label>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Machine type</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="machineType"
								defaultValue={data.hsInfo.machineType}
							/>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Serial No</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="serialNo"
								defaultValue={data.hsInfo.serialNo}
							/>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Machine app version</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="machineAppVersion"
								defaultValue={data.hsInfo.machineAppVersion}
							/>
						</div>
					</div>

					<div className={data.errorType !== "software" ? "hidden" : ""}>
						<div className="divider w-full mt-10">Software Info</div>
						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Nexim</span>
								<input
									type="radio"
									name="softwareType"
									className="radio checked:bg-blue-500"
									checked={data.hsInfo.softwareType === "nexim"}
								/>
							</label>
						</div>
						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Flexa</span>
								<input
									type="radio"
									name="softwareType"
									className="radio checked:bg-blue-500"
									checked={data.hsInfo.softwareType === "flexa"}
								/>
							</label>
						</div>
						<div className="form-control">
							<label className="label cursor-pointer">
								<span className="label-text">Trax</span>
								<input
									type="radio"
									name="softwareType"
									className="radio checked:bg-blue-500"
									checked={data.hsInfo.softwareType === "trax"}
								/>
							</label>
						</div>
						<div className="form-control w-full mb-5">
							<label className="label">
								<span className="label-text">Version</span>
							</label>
							<input
								type="text"
								placeholder="Type here"
								className="input input-bordered w-full"
								name="version"
								defaultValue={data.hsInfo.version}
							/>
						</div>
					</div>
				</form>
			) : (
				<></>
			)}
		</>
	);
}

export default TicketInfo;
