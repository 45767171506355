import { useState } from "react";

export const buildDate = (year, month, day) => {
    const dateBuilded = new Date(year, month - 1, day);
    return dateBuilded
}
export function daysInMonth (month, year) {
    const days =  new Date(year, month, 0).getDate();
    return days
}

export function generateYearList(startDate, numberOfYears) {
    let yearList = [];
    const startYear = startDate.getFullYear();

    for (let i = 0; i < numberOfYears; i++) {
        const year = startYear - i
        const yearFormat = year.toString()
        yearList.push({ year: year, yearFormat: yearFormat });
    }

    return yearList;
}

export default function DateSelector(props) {
    const { dateHandle } = props;
    const currentDatetime = new Date();
    const [day, setDay] = useState(null);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);



    const yearHandle = (event) => {
        setYear(event.target.value)
        if (month && day) {
            dateHandle(buildDate(event.target.value, month, day))
        }
        setDay(null);
    }
    const monthHandle = (event) => {
        setMonth(event.target.value)
        if (year && day) {
            dateHandle(buildDate(year, event.target.value , day))
        }
        setDay(null);
    }
    const dayHandle = (event) => {
        setDay(event.target.value)
        if (year && month) {
            dateHandle(buildDate(year, month, event.target.value))
        }
    }

    return (
        <div className="flex flex-row rounded-lg bg-white w-48">
                    <select
                value={year}
                onChange={yearHandle}
                className="select select-ghost  mr-2">
                <option disabled selected>YYYY</option>
                {
                    generateYearList(currentDatetime, 60).map((data, index) =>
                        <option key={index} value={data.year} label={data.yearFormat} />)
                }
            </select>
            <h1 className="text-4xl">/</h1>
            <select
                value={month}
                onChange={monthHandle}
                className="select select-ghost  ml-2">
                <option disabled selected>MM</option>
                {
                    Array.from({ length: 12 }, (value, index) =>
                        <option key={index} value={index + 1} label={index + 1} />)
                }
            </select>

            <h1 className="text-4xl">/</h1>
            <select
                value={day}
                disabled={!month || !year}
                onChange={dayHandle}
                className="select select-ghost  ml-2">
                <option disabled selected>DD</option>
                {
                    Array.from({ length: daysInMonth(month, year) }, (value, index) =>
                        <option key={index} value={index + 1} label={index + 1} />)
                }
            </select>
            



        </div>
    );
}