import UserList from "../../components/ui/userList";
import ScheduleUser from "../../components/ui/ScheduleUser";
import { useState } from "react";

import axios from "axios";
import { format } from "date-fns";

import { toast } from "react-toastify";

import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function ProfileView() {
	const { setAuth } = useAuth();
	const navigate = useNavigate();

	const handleChangePasswordForm = async event => {
		event.preventDefault();

		console.log(event);

		const currentPassword = event.target.currentPassword.value;
		const newPassword = event.target.newPassword.value;
		const newPasswordCopy = event.target.newPasswordCopy.value;

		console.log(currentPassword, newPassword, newPasswordCopy);

		if (newPassword == newPasswordCopy) {
			const endpoint = process.env.REACT_APP_API_URL + "changePassword";
			try {
				const response = await axios.post(
					endpoint,
					{
						currentPassword: currentPassword,
						newPassword: newPassword,
						newPasswordCopy: newPasswordCopy,
						userId: parseInt(localStorage.getItem("userId")),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setAuth(null);
				navigate("/", { replace: true });
				//setRefresh(!refresh);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			toast.warning("New password don't match!");
		}

		//const userEmail = event.target.userEmail.value;
	};
	return (
		<div className="flex justify-center">
			<div className="w-1/2">
				<div className="w-full divider">Change Password</div>
				<form className="w-full" onSubmit={handleChangePasswordForm}>
					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">Current Password</span>
						</label>
						<input
							type="password"
							placeholder="Type current password here"
							className="input input-bordered w-full"
							name="currentPassword"
							required
						/>
					</div>
					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">New Password</span>
						</label>
						<input
							type="password"
							placeholder="Type new password here"
							className="input input-bordered w-full"
							name="newPassword"
							required
						/>
					</div>
					<div className="form-control w-full ">
						<label className="label">
							<span className="label-text">
								Repeat New Password
							</span>
						</label>
						<input
							type="password"
							placeholder="Type new password here"
							className="input input-bordered w-full"
							name="newPasswordCopy"
							required
						/>
					</div>

					<button type="submit" className="btn btn-wide mt-4 mr-2">
						CHANGE PASSWORD
					</button>
				</form>
			</div>
		</div>
	);
}

export default ProfileView;
