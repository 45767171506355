import React from "react";
import ReactECharts from "echarts-for-react";
import useFetchPost from "../../hooks/useFetchPost";

const SalesPerYear: React.FC = props => {
	const { bodyData } = props;

	const endpoint = process.env.REACT_APP_API_URL + "reports/getSalesPerYear";
	const {
		data: salesPerYearData,
		loading,
		error,
		refetch,
	} = useFetchPost(endpoint, bodyData);
	console.log(salesPerYearData);

	if (loading)
		return <span className="loading loading-spinner loading-lg"></span>;

	if (error) console.error(error);

	const onChartClick = params => {
		console.log("Chart clicked", params);
	};

	const onEvents = {
		click: onChartClick,
	};

	var type = " $";

	if (bodyData.courtesy) {
		type = " Qty";
	}

	const options = {
		grid: { top: 8, right: 8, bottom: 24, left: 68 },
		xAxis: {
			type: "category",
			data: salesPerYearData ? salesPerYearData.titleData : "",
		},
		yAxis: {
			type: "value",
		},
		series: [
			{
				data: salesPerYearData ? salesPerYearData.totalData : "",
				type: "bar",
				name: "Total" + type,
				color: "#aeddab",
				smooth: true,
			},
			{
				data: salesPerYearData ? salesPerYearData.totalFuji : "",
				type: "bar",
				name: "Total FUJI" + type,
				color: "#3a67ab",
				smooth: true,
			},
			{
				data: salesPerYearData ? salesPerYearData.totalSaki : "",
				type: "bar",
				name: "Total SAKI" + type,
				color: "#d00234",
				smooth: true,
			},
		],
		tooltip: {
			trigger: "axis",
		},
		toolbox: {
			feature: {
				dataView: { show: true, readOnly: false },
				restore: { show: true },
				saveAsImage: { show: true },
			},
		},
	};

	return <ReactECharts option={options} onEvents={onEvents} />;
};

export default SalesPerYear;
