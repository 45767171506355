import Menu from "../layouts/Menu";

function Home() {
	return (
		<>
			<h1></h1>
		</>
	);
}

export default Home;
