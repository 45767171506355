/*import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
	const [user, setUser] = useLocalStorage("user", userData);
	const navigate = useNavigate();

	const login = async data => {
		try {
			const endpoint = process.env.REACT_APP_API_URL + "login";
			const response = await axios.post(
				endpoint,
				{
					email: data.email,
					password: data.password,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response.data.userType === "user") {
				//history("/comunidades");
			} else if (response.data.userType === "admin") {
				//history("/dashboard");
			}
			console.log(response.data);
			console.log(true);

			//setUser(response.data);
			navigate("/dashboard/profile", { replace: true });
		} catch (error) {
			console.log("error");
			setUser(null);
			navigate("/", { replace: true });
		}
		//setUser(data);
		//navigate("/dashboard/profile", { replace: true });
	};

	const logout = () => {
		setUser(null);
		navigate("/", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
		}),
		[user]
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};*/

import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
	return useContext(AuthContext);
};

export default useAuth;
