import useFetchGet from "../../hooks/useFetchGet";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function SelectClients(props) {
	const { changeTab, usersSelected } = props;
	const [isActive, setIsActive] = useState(false);

	const options = [
		{ value: "chocolate", label: "Chocolate", custom: "dsa" },
		{ value: "strawberry", label: "Strawberry" },
		{ value: "vanilla", label: "Vanilla" },
	];
	const [selectedOption, setSelectedOption] = useState([]);
	changeTab(selectedOption);

	console.log(selectedOption);

	var currentArea = "";
	const endpoint = process.env.REACT_APP_API_URL + "clientsForSelect";
	const { data, loading, error, refetch } = useFetchGet(endpoint, {
		appName: "schedule",
	});
	console.log(data);

	if (loading) return <h1>LOADING...</h1>;

	if (error) console.error(error);

	return (
		<>
			<Select
				defaultValue={selectedOption}
				onChange={setSelectedOption}
				options={data}
				className="w-full my-react-select-container"
				classNamePrefix="my-react-select"
			/>
		</>
	);
}

export default SelectClients;
