import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import * as echarts from 'echarts';

import TicketsPerYear from "../../components/charts/TicketsPerYear";
import TicketsPerPriority from "../../components/charts/TicketsPerPriority";
import TicketsPerClient from "../../components/charts/TicketsPerClient";
import TicketsPerUser from "../../components/charts/TicketsPerUser";
import TicketsPerState from "../../components/charts/TicketsPerState";


function TicketsDashboard() {




	return (
		<div className=" w-full flex box-border">
			<div className="w-2/6 card box-border mr-4 p-4 flex content-between bg-base-100 text-primary-content">
				  <div class="divider mt-2">Tickets per month</div>
  				<TicketsPerYear/>
				  <div class="divider">Tickets per priority</div>
				<TicketsPerPriority/>
				  <div class="divider">Tickets per cusotomer</div>
				<TicketsPerClient/>
			</div>

			<div className="w-4/6 card box-border p-4 bg-base-100 text-primary-content">
				  <div class="divider mt-2">Tickets per engineer</div>
  				<TicketsPerUser className="w-10/12"/>
				  <div class="divider">Tickets per state</div>
				<TicketsPerState className="w-10/12"/>
			</div>

		</div>
	);
}

export default TicketsDashboard;
