import OrganizationCard from "./OrganizationCard";

import { getUserByLevelHandle } from "../../utils/utils";

export default function OrganizationGroupHead(props) {
    const { usersInfo, dashboardRequest } = props;
    return (
        <div className="w-full">
            <div className="flex w-full mt-12">
                <div className="grow" />
                <div className="flex flex-row rounded-lg m-1 bg-white shadow-xl">
                    {
                        usersInfo.map((user, index) =>
                            <OrganizationCard 
                                key={index} 
                                userInfo={user}
                                dashboardRequest={dashboardRequest} />
                        )
                    }
                </div>
                <div className="grow" />

            </div>
            <div className="flex flex-row h-8">
            <div className="basis-1/2 border-r-4 border-gray-400 border-dashed"/>
            <div className="basis-1/2"/>
            </div>
        </div>
    )
}