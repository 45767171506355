import "../assets/global.css";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import sendEmail from "../utils/sendEmail.js"

function SignUp() {
	//const { login } = useAuth();
	const { setAuth } = useAuth();
	const navigate = useNavigate();
	const handleLogin = async event => {
		event.preventDefault();

		const userEmail = event.target.userEmail.value.trim();
		const userFirstName = event.target.userFirstName.value.trim();
		const userLastName = event.target.userLastName.value.trim();
		const userPassword = event.target.userPassword.value.trim();
		const endpoint = process.env.REACT_APP_API_URL + "users";

		try {
			const response = await axios.post(
				endpoint,
				{
					"name": userFirstName + " " + userLastName,
					"email": userEmail,
					"password": userPassword,
					"area": "NA",
					"jobTitle": "NA",
					"roles": {"userType": "customer", "permissions": {"tickets": "user"}},
					"joinedSince": 2023,
					"accountActive": false
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response.data.status === true) {
								const corList = ["alfredoplazola@pacmexico.com.mx", "danieljimenez@pacmexico.com.mx", "everardojaime@pacmexico.com.mx", "GilbertoVelazco@pacmexico.com.mx", "MarioChavez@pacmexico.com.mx", "OsbaldoRodriguez@pacmexico.com.mx", "osvaldosepulveda@pacmexico.com.mx", "RicardoRivera@pacmexico.com.mx", "carlosjaime@pacmexico.com.mx", "victor@pacmexico.com.mx"];

				sendEmail({
    "to": corList,
    "subject": "New user registered!",
    "body": "name: " + userFirstName + " " + userLastName + ", email: " + userEmail
})
				navigate("/", { replace: true });
			}
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong");
		}
	};

	return (
		<div class="lg:flex bg-white">
			<div class="lg:w-1/2 xl:max-w-screen-sm">
				<div class="py-12 bg-indigo-100 lg:bg-white flex lg:justify-center">
					<img src="/images/pacLogoBlack.svg" width="250" height="125" />
				</div>
				<div class="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
					<h2
						class="text-center text-4xl text-gray-800 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
					>
						PAC Mexico Sign Up
					</h2>
					<div class="mt-12">
						<form onSubmit={handleLogin} action="/schedule">
							<div>
								<div class="text-sm font-bold text-gray-700 tracking-wide">Corporative Email Address</div>
								<input
									name="userEmail"
									class="w-full text-lg py-2 border-b bg-white text-black border-gray-300 focus:outline-none focus:border-indigo-500 pl-2"
									type="text"
									required
									placeholder="Corporative Email Address"
									pattern="^[^@]+@(?!(yahoo|hotmail|gmail|live|outlook))[^@]+\.[a-z]{2,}$" 
								/>
							</div>
							<div className="mt-6">
								<div class="text-sm font-bold text-gray-700 tracking-wide">First Name</div>
								<input
									name="userFirstName"
									class="w-full text-lg py-2 border-b bg-white text-black border-gray-300 focus:outline-none focus:border-indigo-500 pl-2"
									type="text"
									required
									placeholder="First Name"
								/>
							</div>
							<div className="mt-6">
								<div class="text-sm font-bold text-gray-700 tracking-wide">Last Name</div>
								<input
									name="userLastName"
									class="w-full text-lg py-2 border-b bg-white text-black border-gray-300 focus:outline-none focus:border-indigo-500 pl-2"
									type="text"
									required
									placeholder="Last Name"
								/>
							</div>
							<div class="mt-6">
								<div class="flex justify-between items-center">
									<div class="text-sm font-bold text-gray-700 tracking-wide">Password</div>
									<div>
										{/*<a
											class="text-xs font-display font-semibold text-indigo-600 hover:text-indigo-800
                                        cursor-pointer"
										>
											Forgot Password?
										</a>*/}
									</div>
								</div>
								<input
									name="userPassword"
									class="w-full text-lg py-2 border-b bg-white text-black border-gray-300 focus:outline-none focus:border-indigo-500 focus:bg-white pl-2"
									type="password"
									required
									placeholder="Enter your password"
								/>
							</div>
							<div class="mt-10">
								<button
									class="bg-blue-800 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
								>
									Sign Up
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen shadow-inner">
				<div class="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
					<img src="/images/pacLogoBlack.svg" width="500" height="250" />
				</div>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
}

export default SignUp;
