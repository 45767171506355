import ReactECharts from "echarts-for-react";
import useFetchPost from "../../hooks/useFetchPost";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const SalesPerMonth: React.FC = props => {
	const { bodyData } = props;
	const [salesPerYearData, setSalesPerYearData] = useState(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	console.log(bodyData);
	const endpoint = process.env.REACT_APP_API_URL + "reports/getSalesPerMonth";

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.post(endpoint, bodyData, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				console.log(response.data);
				setSalesPerYearData(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [bodyData]);

	var type = " $";

	if (bodyData.courtesy) {
		type = " Qty";
	}

	var series = [
		{
			data: salesPerYearData ? salesPerYearData.totalData : "",
			type: "bar",
			name: "Total" + type,
			color: "#aeddab",
			smooth: true,
		},
		{
			data: salesPerYearData ? salesPerYearData.totalFuji : "",
			type: "bar",
			name: "Total FUJI" + type,
			color: "#3a67ab",
			smooth: true,
		},
		{
			data: salesPerYearData ? salesPerYearData.totalSaki : "",
			type: "bar",
			name: "Total SAKI" + type,
			color: "#d00234",
			smooth: true,
		},
	];

	//if (loading)
	//return <span className="loading loading-spinner loading-lg"></span>;

	const onChartClick = params => {
		console.log("Chart clicked", params);
	};

	const onEvents = {
		click: onChartClick,
	};

	const options = {
		grid: { top: 8, right: 8, bottom: 24, left: 68 },
		xAxis: {
			type: "category",
			data: salesPerYearData ? salesPerYearData.titleData : "",
		},
		yAxis: [
			{
				type: "value",
			},
		],
		series: series,
		tooltip: {
			trigger: "axis",
		},
		toolbox: {
			feature: {
				dataView: { show: true, readOnly: false },
				restore: { show: true },
				saveAsImage: { show: true },
			},
		},
	};

	return <ReactECharts option={options} onEvents={onEvents} />;
};

export default SalesPerMonth;
