import Menu from "../layouts/Menu";

function Test() {
	return (
		<>
			<h1>Test</h1>
		</>
	);
}

export default Test;
