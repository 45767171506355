import OrganizationCard from "./OrganizationCard";
import OrganizationGroupShort from "./OrganizationGroupShort";
import { getUserByLevelHandle } from "../../utils/utils";

export default function OrganizationGroupSupport(props) {
    const { dataChart, usersInfo, dashboardRequest } = props;
    const levelRegional = getUserByLevelHandle(dataChart, 3);
    return (
        <div className="grow ">
            {
                usersInfo.length &&
                (<div className="flex flex-row h-8">
                    <div className="basis-1/2 border-r-4 border-gray-400 border-dashed" />
                </div>)
            }
            <div className="flex ">
                <div className="grow " />
                <div className="flex flex-row rounded-lg m-1 bg-white shadow-xl">
                    {
                        usersInfo.map((user, index) =>
                            <OrganizationCard 
                                key={index} 
                                userInfo={user}
                                dashboardRequest={dashboardRequest} />)
                    }
                </div>

                <div className="grow " />
            </div>
            {
                levelRegional.length &&
                (<div className="flex flex-row h-8">
                    <div className="basis-1/2 border-r-4 border-gray-400 border-dashed" />
                    <div className="basis-1/2" />
                </div>)
            }

            <div className="flex ">
                <div className="grow" />
                {
                    levelRegional.map((user, index) =>

                        <OrganizationGroupShort
                            key={index}
                            dataChart={dataChart}
                            levelRegional={user}
                            index={4}
                            left={index}
                            right={index != (levelRegional.length - 1)}
                            dashboardRequest={dashboardRequest} />

                    )
                }
                <div className="grow " />
            </div>
        </div>
    );
}