import { handleImage } from "../../utils/utils";
export default function UserOrgInfo(props) {
    const { userInfo } = props;
    const profile_image = userInfo.profile_image ? handleImage(userInfo.profile_image.data) : "/images/Profile_Default.png"
    return (
        <form onSubmit={e => { e.preventDefault(); }} className="ring-4 ring-blue-400 rounded-lg p-2">
            <figure className="mb-4">
                <img
                    src={profile_image}
                    alt="profile"
                    width="100%" />
            </figure>
            <div className="rounded-lg bg-blue-100 p-2">
            <h1 className="text-black font-bold">🗃️ Organization:</h1>
            <p>{userInfo.organization}</p>
            <h1 className="text-black font-bold">💼 Department:</h1>
            <p>{userInfo.department}</p>
            <h1 className="text-black font-bold">👨‍💼 User Name:</h1>
            <p>{userInfo.user_name}</p>
            <h1 className="text-black font-bold">📧 Email:</h1>
            <p>{userInfo.email}</p>
            <h1 className="text-black font-bold">🧰 Position:</h1>
            <p>{userInfo.user_position}</p>
            <h1 className="text-black font-bold">📍 City:</h1>
            <p>{userInfo.user_city}</p>
            <h1 className="text-black font-bold">🗺️ Area:</h1>
            <p>{userInfo.user_area}</p>
            <h1 className="text-black font-bold">📲 Phone Number:</h1>
            <p>{userInfo.phone_number ? userInfo.phone_number : "None"}</p>
            <h1 className="text-black font-bold">📆 Bridthday:</h1>
            <p>{userInfo.birthday_date ? userInfo.birthday_date.split('T')[0] : "None"}</p>
            <h1 className="text-black font-bold">👨‍🏫 Direct Superior:</h1>
            <p>{userInfo.superior_name ? userInfo.superior_name : "None"}</p>
            </div>

        </form>
    );
}