import React, { useState, useEffect, useRef } from 'react';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import useFetch from '../../hooks/useFetch';
import { useReactToPrint } from 'react-to-print';
import ComponentFileDb from './ComponentFileDb';
import { useTypeKey } from '../../hooks/useTypeKey';
import { saveAs } from 'file-saver';
import { getUserData } from '../../utils/utils';

export default function ReportViewer(props) {
    const { handleForm, filterValues, dashboardRequest } = props;
    const [showRep, setShowRep] = useState(false);
    const containerPrintRef = useRef(null);
    const userInfo = getUserData();
    const getExpenseReport = useFetch(`${process.env.REACT_APP_API_URL}get_expense_report_user`,
        filterValues
    )
    const { loading, error, handleRequest } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}${filterValues.user_id ? "update_expense_report_user_file" : "create_expense_report_users"}`,
        filterValues,
        dashboardRequest,
        handleForm
    )
    const updateReportFetch = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}create_expense_report_user`,
        filterValues,
        dashboardRequest,
        handleForm
    )
    useEffect(() => {
        getExpenseReport.handleRequest();
    }, [])
    const handlePrint = useReactToPrint({
        content: () => containerPrintRef.current,
        documentTitle: "Print This Document",
    })
    const typeKeyHandle = () => {
        handleForm(false);
    }
    const handleDownload = () => {
        const file2 = new Blob([new Uint8Array(getExpenseReport.data[0].file_content.data)]);
        saveAs(file2, getExpenseReport.data[0].file_name);
    };
    useTypeKey(typeKeyHandle, "Escape");
    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className="bg-base-200 p-6 rounded-lg shadow-lg relative w-full max-h-full overflow-y-auto">
                    <h3 className="font-bold text-lg mb-4">Report Viewer</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                        <div className='py-4'>
                            {
                                userInfo.isAdmin ?
                                    <button
                                        onClick={() => handleRequest()}
                                        className="btn btn-warning mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                        </svg>
                                        Create Report
                                    </button> : null
                            }
                                                        {
                                !getExpenseReport.data.length && filterValues.user_id ?
                                    <button
                                        onClick={() => updateReportFetch.handleRequest()}
                                        className="btn btn-success mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                        </svg>
                                        Audit
                                    </button> : null}

                            {
                                getExpenseReport.data.length && getExpenseReport.data[0].file_name ?
                                    <button
                                        onClick={() => setShowRep(!showRep)}
                                        className="btn btn-accent mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                        </svg>
                                        Show Report
                                    </button> : null}
                            {
                                getExpenseReport.data.length && getExpenseReport.data[0].file_name ?
                                    <button
                                        onClick={handleDownload}
                                        className="btn btn-info mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                        </svg>
                                        Download
                                    </button> : null}
                            {
                                showRep ?
                                    <button
                                        onClick={handlePrint}
                                        className="btn btn-secondary mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z" />
                                        </svg>
                                        Print
                                    </button> : null}

                        </div>
                        {(!getExpenseReport.data.length && !getExpenseReport.loading) && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-info">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 shrink-0 stroke-current"
                                fill="none"
                                viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>There are No Files to show! </span>
                        </div>)}
                        {
                            getExpenseReport.data.length ?
                                (<div className='bg-gray-800 p-4 text-white rounded-lg mb-10'>
                                    <h1>Status:{getExpenseReport.data[0].file_name?"AUDIT":"PENDING"}</h1>
                                    {getExpenseReport.data[0].file_name?
                                    <h1>File Name:<span>{getExpenseReport.data[0].file_name}</span></h1>:null}
                                    <h1>Report Id:<span>{getExpenseReport.data[0].expense_report_id}</span></h1>
                                    <h1>Month Num:<span>{getExpenseReport.data[0].month}</span></h1>
                                    <h1>From:<span>{getExpenseReport.data[0].from_date.slice(0, 10)}</span></h1>
                                    <h1>To:<span>{getExpenseReport.data[0].to_date.slice(0, 10)}</span></h1>
                                    <h1>Month Num:<span>{getExpenseReport.data[0].month}</span></h1>
                                </div>) : null
                        }
                        {
                            getExpenseReport.data.length && showRep ?
                                <ComponentFileDb
                                    printRef={containerPrintRef}
                                    fileName={getExpenseReport.data[0].file_name}
                                    file={{ data: getExpenseReport.data[0].file_content.data }}
                                    fistPage={false}
                                    hoverColor={false} /> : null
                        }

                    </form>
                </div>
            </div>
        </>
    );
}


