import React, { useState, useEffect } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useFetchPost from "../../hooks/useFetchPost";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { toast } from "react-toastify";
import sendEmail from "../../utils/sendEmail.js"


function TicketsUsersTable(props) {
	const { changeTicket, userIdSelected, refresh, tickets } = props;
	const userId = localStorage.getItem("userId")
	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "customersFromApp/tickets";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [test, setTest] = useState(null);

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data);
				console.log(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	if (loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	const approveAccount = async event => {
		event.preventDefault();
		const userId = event.target.dataset.id;
		const userEmail = event.target.dataset.email;
		
		const endpoint = process.env.REACT_APP_API_URL + "approveAccount/" + userId;
		try {
			const response = await axios.get(
				endpoint,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			console.log(response.data);
			console.log(true);
			toast.success("Account approved");
			setRefetch(!refetch);

			sendEmail({"to": userEmail, "subject": "PAC Mexico account", "body": "Hello, Your account has been approved!"});
		} catch (error) {
			console.log(error);
			toast.error(error);
		}
	};

	return (
		<>
			<div className="card overflow-y-auto bg-base-100">
				{data !== null && data.users.length > 0 ? (
<table className="table">
					{/* head */}
					<thead>
						<tr>
							<th>
								
							</th>
							<th>#</th>
							<th>Name</th>
							<th>Email</th>
							<th>Roles</th>
							<th>Active ?</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{data !== null ? (
							data.users.map((user, i) => (
								<tr>
									<td>
										<label>
											<input type="checkbox" className="checkbox" />
										</label>
									</td>
									<td>{user.id}</td>
									<td>{user.name}</td>
									<td>{user.email}</td>
									<td><pre>{JSON.stringify(user.roles.permissions, null, 2)}</pre></td>
									<td>{user.accountActive.toString()}</td>
									<td className="flex justify-center">
										{user.accountActive === false ? 
										<button
											className="btn bg-accent "
											data-id={user.id}
											data-email={user.email}
											onClick={
												approveAccount
											}
										>
											Approve account
										</button>
										: <></>}
									</td>
								</tr>
							))
						) : (
							<div className="w-full h-full flex justify-center align-middle">
				<div>No data</div>
			</div>
						)}
					</tbody>
					{/* foot
					<tfoot>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Job</th>
							<th>Favorite Color</th>
							<th></th>
						</tr>
					</tfoot>
                    */}
				</table>
				) : (<div className="w-full h-full flex justify-center align-middle">
				<div>No data</div>
			</div>)

				}
				
			</div>
		</>
	);
}

export default TicketsUsersTable;
