import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Menu from "../layouts/Menu";

const RequireAuth = ({ allowedRoles }) => {
	const { auth } = useAuth();
	const location = useLocation();
	//return auth?.roles?.find(role => allowedRoles?.includes(role)) ? (
	return auth?.accessToken ? (
		<Menu roles={auth.roles} />
	) : auth?.user ? (
		<Navigate to="/" state={{ from: location }} replace />
	) : (
		<Navigate to="/" state={{ from: location }} replace />
	);
};

export default RequireAuth;
