import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";

function ReportsTable(props) {
	const { bodyData } = props;
	const endpoint = process.env.REACT_APP_API_URL + "reports";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const tableRef = useRef(null);

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.post(endpoint, bodyData, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data);
				console.log("tickets: ", response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [bodyData]);

	const order = by => {
		const strAscending = [...data].sort((a, b) => (a.by > b.by ? 1 : -1));
		setData(strAscending);
		console.log(strAscending);
	};

	if (loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	const header = [
		"id",
		"reportId",
		"customerId",
		"customerName",
		"personInCharge",
		"userName",
		"reportType",
		"reportType2",
		"reportTypeFS",
		"total",
		"quote",
		"po",
		"dateReportCompleted",
		"date",
		"status",
	];

	function handleDownloadExcel() {
		downloadExcel({
			fileName: "react-export-table-to-excel -> downloadExcel method",
			sheet: "react-export-table-to-excel",
			tablePayload: {
				header,
				body: data,
			},
		});
	}
	return (
		<>
			<div className="h-full bg-base-100">
				<button
					className="w-full btn btn-wide mb-4 bg-accent"
					onClick={handleDownloadExcel}
				>
					Download Excel
				</button>

				{data !== null && data.length > 0 ? (
					<table className="table h-full" ref={tableRef}>
						{/* head */}
						<thead>
							<tr>
								<th
									onClick={() => {
										order("id");
									}}
								>
									#
								</th>
								<th>Customer Name</th>
								<th>Client</th>
								<th>FSE</th>
								<th>Report Type</th>
								<th>FUJI / SAKI</th>
								<th>Total</th>
								<th>Date</th>
								<th>Status</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data !== null ? (
								data.map((report, i) => (
									<tr>
										<td>{report.reportId}</td>
										<td>{report.customerName}</td>
										<td>{report.personInCharge}</td>
										<td>{report.userName}</td>
										<td>{report.reportType}</td>
										<td>{report.reportTypeFS}</td>
										<td>{report.total}</td>
										<td>{report.dateReportCompleted}</td>
										<td>{report.status}</td>
										<td>
											<a
												class="btn btn-accent"
												href={`https://intranet.pacmexico.com/pdf/showReport.php?reportId=${report.reportId}`}
												target="_blank"
											>
												Open
											</a>
										</td>
									</tr>
								))
							) : (
								<div className="w-full h-full flex justify-center align-middle">
									<div>No data</div>
								</div>
							)}
						</tbody>
						{/* foot
					<tfoot>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Job</th>
							<th>Favorite Color</th>
							<th></th>
						</tr>
					</tfoot>
                    */}
					</table>
				) : (
					<div className="w-full h-full flex justify-center align-middle">
						<div>No data</div>
					</div>
				)}
			</div>
		</>
	);
}

export default ReportsTable;
