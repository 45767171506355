import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import Ticket from "../../components/ui/Ticket";
import TicketsTable from "../../components/ui/TicketsTable";
import SalesPerYear from "../../components/charts/SalesPerYear";
import SalesPerMonth from "../../components/charts/SalesPerMonth";
import ReportsTable from "../../components/ui/ReportsTable";
import SalesPerState from "../../components/charts/SalesPerState";
import SelectUsers from "../../components/form/selectUsers";
import SelectCustomers from "../../components/form/selectCustomers";

const moment = require("moment");

function ReportsCharts({ socket }) {
	console.log(socket);
	const tickets = localStorage.getItem("tickets");
	console.log(tickets);
	const [active, setActive] = useState(0);
	const [fseinfoActive, setFseInfoActive] = useState(null);
	const [openTickets, setOpenTickets] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [userAssigned, setUserAssigned] = useState();

	const [customersSelected, setCustomersSelected] = useState();

	const toDate = moment().format("YYYY-MM-DD");
	const toDateArr = toDate.split("-");
	const fromDate = parseInt(toDateArr[0]) - 1 + "-" + toDateArr[1] + "-01";

	const changeUsers = index => {
		console.log(index);
		setUserAssigned(index);
		console.log(userAssigned);
	};

	const changeCustomers = index => {
		console.log(index);
		setCustomersSelected(index);
	};

	console.log(fromDate, toDate);
	console.log("Current Date:", moment().toString());
	console.log("Current date is:", moment().date());

	const [bodyData, setBodyData] = useState({
		startDate: "2023/01/01",
		endDate: "2023/12/31",
		reportType1: "",
		reportTypeFS: "",
		courtesy: "",
		fseName: userAssigned,
		clientName: customersSelected,
		//118498.00545734963
	});

	console.log(active);
	console.log(fseinfoActive);
	console.log(openTickets);

	/*var bodyData = {
		startDate: "2023/01/01",
		endDate: "2023/12/31",
		reportType1: "",
		reportTypeFS: "",
		courtesy: "",
		fseName: [],
		//118498.00545734963
	};*/

	const handleUpdateCharts = async event => {
		event.preventDefault();
		const principal = event.target.principal.value;
		const billable = event.target.billable.value;
		const serviceType = event.target.serviceType.value;
		const area = event.target.area.value;
		const from = event.target.from.value;
		const to = event.target.to.value;

		bodyData.startDate = from;
		bodyData.endDate = to;

		setBodyData({
			startDate: from,
			endDate: to,
			reportType1: serviceType,
			reportTypeFS: principal,
			courtesy: billable,
			fseName: userAssigned,
			clientName: customersSelected,
			//118498.00545734963
		});

		console.log(principal, billable, serviceType, area, from, to);
		console.log(bodyData);
	};

	return (
		<div>
			<form
				onSubmit={handleUpdateCharts}
				className="w-full h-auto card box-border p-4 grid grid-cols-7 gap-4 bg-base-100 "
			>
				<div className="form-control">
					<label className="label">
						<span className="label-text">Principal</span>
					</label>
					<select className="input input-bordered w-full" name="principal">
						<option value="" label="All" />
						<option value="FUJI" label="FUJI" />
						<option value="SAKI" label="SAKI" />
						<option value="Sales" label="Sales" />
					</select>
				</div>

				<div className="form-control">
					<label className="label">
						<span className="label-text">billable</span>
					</label>
					<select className="input input-bordered w-full" name="billable">
						<option value="" label="All" />
						{/*<option value="Billable" label="Billable" />*/}
						<option value="Free of Charge" label="Free of Charge" />
					</select>
				</div>

				<div className="form-control">
					<label className="label">
						<span className="label-text">Service Type</span>
					</label>
					<select className="input input-bordered w-full" name="serviceType">
						<option value="" label="All" />
						<option value="Charge Call" label="Charge Call" />
						<option value="Head Repair" label="Head Repair" />
						<option value="Warranty" label="Warranty" />
						<option value="Courtesy" label="Courtesy" />
						<option value="Online" label="Online" />
						<option value="Onsite" label="Onsite" />
					</select>
					{/*<select className="input input-bordered w-full" name="serviceType">
						<option value="All" label="All" />
						<option value="Head Repair" label="Head Repair" />
						<option value="Online" label="Online" />
						<option value="Installation" label="Installation" />
						<option value="Repair" label="Repair" />
						<option value="Training" label="Training" />
						<option value="Retrofit" label="Retrofit" />
						<option value="Database" label="Database" />
						<option value="Upgrade" label="Upgrade" />
						<option value="Other" label="Other" />
	</select>*/}
				</div>

				<div className="form-control hidden">
					<label className="label">
						<span className="label-text">Area</span>
					</label>
					<select className="input input-bordered w-full" name="area">
						<option value="" label="All" />
						<option value="Hardware" label="Hardware" />
						<option value="Software" label="Software" />
						<option value="Head Repair" label="Head Repair" />
					</select>
				</div>

				<div className="form-control">
					<label className="label">
						<span className="label-text">FSE</span>
					</label>
					<SelectUsers changeUsers={changeUsers} />
				</div>

				<div className="form-control">
					<label className="label">
						<span className="label-text">Customer</span>
					</label>
					<SelectCustomers changeCustomers={changeCustomers} />
				</div>

				<div className="form-control w-full ">
					<label className="label">
						<span className="label-text">From</span>
					</label>
					<input
						type="date"
						placeholder="Type here"
						className="input input-bordered w-full"
						name="from"
						defaultValue={fromDate}
					/>
				</div>

				<div className="form-control w-full ">
					<label className="label">
						<span className="label-text">To</span>
					</label>
					<input
						type="date"
						placeholder="Type here"
						className="input input-bordered w-full"
						name="to"
						defaultValue={toDate}
					/>
				</div>

				<button
					className="w-full btn btn-wide mt-9 mr-2 bg-accent"
					onSubmit={handleUpdateCharts}
				>
					SEARCH
				</button>
			</form>

			<div className="w-full card box-border mt-4 p-6 flex content-between bg-base-100 ">
				<SalesPerMonth bodyData={bodyData}></SalesPerMonth>
			</div>

			<div className="w-full card box-border mt-6 p-6 flex content-between bg-base-100 ">
				<SalesPerState bodyData={bodyData}></SalesPerState>
			</div>

			<div className="w-full card box-border mt-6 p-6 flex content-between bg-base-100 ">
				<SalesPerYear bodyData={bodyData}></SalesPerYear>
			</div>

			<div className="w-full card box-border mt-6 p-6 flex content-between bg-base-100">
				<ReportsTable bodyData={bodyData}></ReportsTable>
			</div>
		</div>
	);
}

export default ReportsCharts;
