import { useEffect, useState } from "react";
import axios from "axios";

function useFetchPost(url, body) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		console.log("ready");
		setLoading(true);
		axios
			.post(url, body, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [url]);

	const refetch = () => {
		setLoading(true);
		axios
			.post(url, body, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return { data, loading, error, refetch };
}

export default useFetchPost;
