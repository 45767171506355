import { useState } from "react";

const getColumnsNames = (dataTable) => {
    if (dataTable.length) {
        const firstRow = dataTable[0];
        const columns = Object.keys(firstRow)
        return columns
    }
    else {
        return []
    }

}
const buildTableBody = (dataTable, columns, rowStyle="", colStyle="") => {
    const tableBody = dataTable.map((row, ind)=>
        <tr key={ind} className={rowStyle}>
            {
                columns.map((col, ind2)=>
                    <td className={colStyle}
                        key={ind2}>{row[col]}</td>
                )
            }
        </tr>
    )
    return tableBody
}

const pageHandle= ( data, page, number )=>{
    const startInd = number * (page - 1);
    const endInd = (number * page) + 1;
    const pageData = data.slice(startInd, endInd)
    return pageData
}

export default function TableBasic(props) {
    const { data, styleBody="bg-white", styleCols="bg-white", 
        styleRows="border-t-2 border-gray-300", styleButton="", rowForPage = 2 } = props;
    const [ page, setPage] = useState(1);

    const pageData = pageHandle(data, page,rowForPage);
    const ColumnsNames = getColumnsNames(pageData);
    const tableBody = buildTableBody(pageData, ColumnsNames, styleRows);
    
    const nextClickHandle = () =>{
        setPage(page + 1);
    }
    const prevClickHandle = () =>{
        setPage(page - 1);
    }

    return (
        <div className="overflow-x-auto mt-4">
            <table className="table">
                <thead className={styleCols}>
                    <tr>
                        {
                            ColumnsNames.map((columnName, ind) =>
                                <th key={ind}>{columnName}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody className={styleBody}>
                    {tableBody}
                </tbody>
            </table>
            <div className="flex mt-4">
                { page!=1 && (<button
                    onClick={prevClickHandle} 
                    className={styleBody}>{"Prev"}</button>)}
                <div className="grow"/>
                { data.length > (page * rowForPage) && (<button 
                    onClick={nextClickHandle} 
                    className={styleBody}>{"Next"}</button>)}
            </div>
        </div>
    );
}