import { useState } from "react";

export default function RoleTable({ data, permissions, setBodyFetch, bodyFetch }) {
    const [ permissionsIn, setPermissionsIn] = useState(bodyFetch.roles.permissions ? bodyFetch.roles.permissions: {})
    const checkPermissions = (key, val) => {
        if (!val) {
            const delVal = {
                ...permissionsIn
            }
            delete delVal[key];
            setPermissionsIn(delVal);
        }
        else {
            setPermissionsIn({
                ...permissionsIn,
                [key]: "user"
            })
        }
    }
    console.log(permissionsIn)
    return (
        <div className="overflow-x-auto">
            <table className="table">
                <thead>
                    <tr>
                        <th>App</th>
                        <th>Enable</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => <tr>
                        <td>{row.name}</td>
                        <td>
                            <input
                                onChange={(e) => checkPermissions(row.name, e.target.checked)}
                                checked={row.name in permissionsIn}
                                type="checkbox"
                                className="checkbox checkbox-primary" />
                        </td>
                        <td>
                            <select
                                onChange={checkPermissions}
                                className="input input-bordered border-gray-400"
                            >
                                {
                                    row.permissions.map((value, index) =>
                                        <option key={index} value={value} label={value} />)
                                }
                            </select>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    );
}