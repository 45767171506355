import UserList from "../../components/ui/userList";
import ScheduleUser from "../../components/ui/ScheduleUser";
import React, { useState, useEffect, useRef } from "react";
import SelectUsers from "../../components/form/selectUsers";
import ChatBar from "./chatBar";

import axios from "axios";
import { format } from "date-fns";

import { toast } from "react-toastify";
import SelectClients from "../../components/form/selectClients";
import Chats from "./chats";
import { io } from "socket.io-client";

function ChatTicket(props) {
	const { ticketId, fseInfo, refresh, ticketData, socket } = props;
	const tickets = localStorage.getItem("tickets");
	console.log("chatTicket", socket);
	const [userAssigned, setUserAssigned] = useState({});
	const [clientSelected, setClientSelected] = useState();
	const [typingStatus, setTypingStatus] = useState('');

	var [refetch, setRefetch] = useState(false);
	var [refresh2, setRefresh2] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [test, setTest] = useState(null);

	const [messages, setMessages] = useState([]);
	console.log("messages: ", messages);
	const lastMessageRef = useRef(null);
	const endpoint = process.env.REACT_APP_API_URL + "chats/" + ticketId;

	const messagesColumnRef = useRef(null); // Add this

	// Runs whenever a socket event is recieved from the server
	/*useEffect(() => {
		socket.on("receiveMessage", data => {
			console.log("receiveMessage: ", data);
			setMessages(state => [
				...state,
				{
					data: data.data,
					userId: data.userId,
					user: { name: data.userName },
					date: data.date,
					createdAt: data.date,
					type: data.type,
					// __createdtime__: data.__createdtime__,
				},
			]);
		});

		// Remove event listener on component unmount
		return () => socket.off("receiveMessage");
	}, [socket]);*/

	// Add this
	/*useEffect(() => {
		// Last 100 messages sent in the chat room (fetched from the db in backend)
		socket.on("messagesData", messagesData => {
			console.log("receiveMessage2: ", data);
			console.log("messagesData:", messagesData.data);
			//messagesData = JSON.parse(messagesData);
			// Sort these messages by __createdtime__
			//messagesData = sortMessagesByDate(last100Messages);
			setMessages(state => [...messagesData.data, ...state]);
		});

		return () => socket.off("messagesData");
	}, [socket]);*/

	// Add this
	// Scroll to the most recent message

	/*useEffect(() => {
    	socket.on('messageResponse', (data) => setMessages([...messages, data]));
  	}, [socket, messages]);

	useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);*/

	useEffect(() => {
		socket.on("receiveMessage", data => setMessages([...messages, data]));
		console.log("data received: ", data);
		    socket.on('typingResponse', (data) => setTypingStatus(data));

	}, [socket, messages]);

	if (loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	const changeTab = index => {
		console.log(index);
		setClientSelected(index.id);
	};

	const changeUsers = index => {
		//console.log(index);
		setUserAssigned(index);
		console.log(userAssigned);
	};

	const handleNewChat = async event => {
		event.preventDefault();

		const message = event.target.data.value.trim();
		const room = "ticket-" + ticketId;
		const userId = localStorage.getItem("userId");
		const userName = localStorage.getItem("userName");
		const type = "message";
		const date = new Date().toISOString();
		console.log("newChat: ", {
			userId,
			userName,
			room,
			message,
			ticketId,
			type,
			date,
			tickets,
		});
		socket.emit("sendMessage", {
			userId,
			userName,
			room,
			message,
			ticketId,
			type,
			date,
			tickets,
		});

		event.target.data.value = "";

		/*if (data !== "") {
			const endpoint = process.env.REACT_APP_API_URL + "chats";
			try {
				const response = await axios.post(
					endpoint,
					{
						ticketId: ticketId,
						userId: parseInt(localStorage.getItem("userId")),
						type: "message",
						data: data,
						date: new Date().toJSON().slice(0, 10),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				//toast.success(response.data.message);
				setRefresh2(!refresh2);
				socket.emit("data", "refresh");
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}*/

		//const userEmail = event.target.userEmail.value;
	};

	const handleTyping = () => {
		socket.emit('typing', `${localStorage.getItem('userName')} is typing`);
	}

	return (
		<div className="w-full h-full flex flex-wrap lg:flex-nowrap content-start">
			<div className="w-full h-full">
				<ChatBar socket={socket} />
				<div class="divider"></div>
				<div
					id="list"
					className="max-h-[25rem] lg:h-full lg:max-h-[32rem] overflow-y-scroll "
					ref={messagesColumnRef}
				>
					<Chats messages={messages} socket={socket} />
				</div>
				
				<div className="flex items-center message__status text-sm">
  					<p>{typingStatus} <span class="loading loading-dots loading-xs"></span>
</p>
				</div>
				<form
					onSubmit={handleNewChat}
					className="w-full mt-6 flex flex-wrap content-end"
				>
					<textarea
						className="textarea textarea-bordered w-full h-2/4"
						placeholder="Type here"
						name="data"
						defaultValue=""
						onKeyDown={handleTyping}
					></textarea>
					<button type="submit" className="w-full btn btn-wide mt-4 mr-2 bg-accent">
						SEND
					</button>
				</form>
			</div>
		</div>
	);
}

export default ChatTicket;
