import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { AuthProvider } from "../context/AuthProvider";

export const AuthLayout = () => {
	const outlet = useOutlet();

	const { userPromise } = useLoaderData();

	return (
		<Suspense fallback={<h1>loading</h1>}>
			<Await
				resolve={userPromise}
				errorElement={<h1>Something went wrong!</h1>}
				children={user => (
					<AuthProvider userData={user}>{outlet}</AuthProvider>
				)}
			/>
		</Suspense>
	);
};
