import { useEffect } from "react";
import ComponentFileDb from "./ComponentFileDb";
import LoadingBar from "../ui/LoadingBar";
import useFetch from "../../hooks/useFetch";

export default function UserOrgDocMain(props) {
    const { fileField, handleFilesChange, filesData, loading, submitFileDeleteHandle } = props;


    return (
        <div>
            {
                (filesData.length && fileField.file_exists) ?
                (<ComponentFileDb
                    file={{ data: filesData[0].file_binary.data }}
                    fistPage={true}
                    fileName={filesData[0].file_name}
                    hoverColor={false} />):null
            }
            <LoadingBar loading={loading} style="mt-2"/>
            <div className="flex flex-row mt-4">

                <label className="btn btn-square btn-info mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                    <input type="file" className="hidden" onChange={handleFilesChange} />
                </label>
                <div class="grow" />
                <button
                    onClick={submitFileDeleteHandle}
                    className="btn btn-square btn-error mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                    </svg>
                </button>
            </div>
        </div>
    );
}