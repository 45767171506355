import React, { useState, useEffect } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import useFetchPost from "../../hooks/useFetchPost";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { toast } from "react-toastify";

function Interactions(props) {
	const { ticketId, changeTicket, userIdSelected, refresh } = props;
	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "actions/" + ticketId;
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [test, setTest] = useState(null);

	useEffect(() => {
		console.log("ready");
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data);
				console.log(data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	if (loading)
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	return (
		<>
			<h2 class="card-title">Interactions</h2>
			<div class="divider"></div>
			<ul class="steps steps-vertical">
				{/*<li data-content="?" class="step step-neutral">
					Interaction 1
				</li>
				<li data-content="!" class="step step-neutral">
					Interaction 2
				</li>
				<li data-content="✓" class="step step-neutral">
					Interaction 3
				</li>
				<li data-content="✕" class="step step-error step-neutral">
					Interaction 4
				</li>
				<li data-content="★" class="step step-neutral">
					Interaction 5
				</li>
				<li data-content="" class="step step-neutral">
					Interaction 6
				</li>
				<li data-content="●" class="step step-neutral">
					Interaction 7
				</li>*/}
				{data !== null ? (
					data.map((action, i) => (
						<li data-content="●" class="step step-neutral">
							<div className="text-xs	">{action.data}, {action.user.name}
							<div className="chat-footer opacity-50 text-xs	">
    {new Date(action.date).toLocaleString()}
  </div></div>
						</li>
					))
				) : (
					<div>no data</div>
				)}
			</ul>
		</>
	);
}

export default Interactions;
