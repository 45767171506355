import UserList from "../../components/ui/userList";
import ScheduleUser from "../../components/ui/ScheduleUser";
import { useState } from "react";
import SelectUsers from "../../components/form/selectUsers";

import axios from "axios";
import { format } from "date-fns";

import { toast } from "react-toastify";
import SelectClients from "../../components/form/selectClients";

import TicketForm from "../../components/form/ticketForm";

function NewTicketView() {
	return (
		<div className="w-full h-full p-8">
			<TicketForm />
		</div>
	);
}

export default NewTicketView;
