import React from "react";
import ReactECharts from "echarts-for-react";

const TicketsPerYear: React.FC = () => {
	const options = {
		grid: { top: 8, right: 8, bottom: 24, left: 36 },
		xAxis: {
			type: "category",
			data: [
				"Dic 2022",
				"Jan 2023",
				"Feb 2023",
				"Mar 2023",
				"Apr 2023",
				"May 2023",
				"Jun 2023",
				"Jul 2023",
				"Aug 2023",
				"Sep 2023",
				"Oct 2023",
				"Nov 2023",
			],
		},
		yAxis: {
			type: "value",
		},
		series: [
			{
				data: [752, 760, 661, 799, 817, 678, 306, 573, 624, 321, 562, 180],
				type: "bar",
				name: "Total",
				stack: "one",
				smooth: true,
			},
			{
				data: [152, 60, 41, 99, 17, 78, 6, 3, 24, 21, 2, 90],
				type: "bar",
				name: "Open",
				color: "orange",
				stack: "one",
				smooth: true,
			},
			{
				data: [600, 700, 620, 700, 800, 600, 300, 570, 600, 300, 560, 90],
				type: "bar",
				name: "Closed",
				stack: "one",
				smooth: true,
			},
		],
		tooltip: {
			trigger: "axis",
		},
		toolbox: {
			feature: {
				dataView: { show: true, readOnly: false },
				restore: { show: true },
				saveAsImage: { show: true },
			},
		},
	};

	return <ReactECharts option={options} />;
};

export default TicketsPerYear;
