import { Link, Outlet } from "react-router-dom";
import TicketForm from "../form/ticketForm";
import TicketInfo from "../ui/TicketInfo";
import ChatTicket from "./chatTicket";
import Interactions from "./interactions";
import { useState } from "react";

import { io } from "socket.io-client";

function Ticket(props) {
	var { fseInfo, ticketId, refresh, socket } = props;
	console.log("ticket socket", socket);
	console.log(props);
	var ticketData = "";
	var tickets = localStorage.getItem("tickets");

	socket.emit("joinRoom", {
		userId: localStorage.getItem("userId"),
		userName: localStorage.getItem("userName"),
		userEmail: localStorage.getItem("userEmail"),
		ticketId: ticketId,
	});

	socket.emit("newUser", {
		userId: localStorage.getItem("userId"),
		userName: localStorage.getItem("userName"),
		userEmail: localStorage.getItem("userEmail"),
		ticketId: ticketId,
	});

	console.log(socket);

	/*
	<div className=" w-2/6 p-6 max-h-[60rem] overflow-y-scroll hidden lg:block">
				<Interactions ticketId={ticketId} refresh={refresh} />
			</div> */

	return (
		<div className="flex max-h-[calc(84vh)] flex-wrap lg:flex-nowrap bg-base-100">
			<div className="w-2/6 p-6 pb-10 max-h-[60rem] overflow-y-scroll hidden lg:block">
				{tickets == "admin" ? (
					<TicketForm ticketId={ticketId} refresh={refresh} />
				) : tickets == "fse" ? (
					<TicketForm ticketId={ticketId} refresh={refresh} />
				) : tickets == "user" ? (
					<TicketInfo ticketId={ticketId} refresh={refresh} />
				) : (
					<></>
				)}
			</div>
			<div className="border-l-2 border-r-2 border-base-200 w-full lg:w-4/6 p-6">
				<ChatTicket
					fseInfo={fseInfo}
					ticketId={ticketId}
					refresh={refresh}
					ticketData={ticketData}
					socket={socket}
				/>
			</div>
		</div>
	);
}

export default Ticket;
