export default function LoadingBar({ loading , style}) {
    return (
        <div className={style}>
            {
                loading &&
                (<progress className="progress w-full progress-info"></progress>)
            }
        </div>

    );
}